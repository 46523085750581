<template>
  <div class="home-box">
    <div class="header">
      <div class="container">
        <div class="headerInner">
          <div class="row jcb ai headToprow">
            <div class="col-lg-3 rel ai jcb">
              <!-- <a @click="clickIcon">
                <img
                  src="https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/logo2.png"
                  class="logo-main"
                />
              </a> -->
              <i class="fa fa-bars barsBtn"></i>
            </div>
            <div class="col-lg-4">
              <el-form @submit.prevent="onSubmit">
                <div class="headSearchFlex flex">
                  <el-input
                    v-model="searchQuery"
                    name="search"
                    class="searchTop"
                    placeholder=""
                    :suffix-icon="Search"
                  />
                  <!-- <el-button
                  type="primary"
                  native-type="submit"
                  class="searchBtnTop"
                >
                  <i class="fa fa-search"></i>
                </el-button> -->
                </div>
              </el-form>
            </div>
            <!-- <div class="col-lg-5 hide992">
              <div class="topLinksflex flex jce ai">
                <div class="theme_buttom">
                  <a rel="nofollow">
                    <el-icon><Sunny /></el-icon>
                  </a>
                  &nbsp;
                  <a rel="nofollow">
                    <el-icon><Moon /></el-icon>
                  </a>
                </div>
                <a class="headLink">New</a>
                <a class="headLink">Trending</a>
                <a class="headLink">Top Picks</a>

                <a class="headLogBtn logBtn"
                  ><i class="fa fa-lock"></i> Login</a
                >
                <a class="headLogBtn regBtn"
                  ><i class="fa fa-user"></i> Register</a
                >
              </div>
            </div> -->
          </div>
          <div class="headerNav hide992">
            <ul class="mainUl">
              <li v-for="item in categories" :key="item.name">
                <a class="main-link" :href="item.url">
                  <!-- <img :src="item.src" :alt="item.alt" /> -->
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- xmxmy -->
    <amp-ad
      width="100vw"
      height="320"
      type="adsense"
      data-ad-client="ca-pub-6741681473423260"
      data-ad-slot="2805789712"
      data-auto-format="rspv"
      data-full-width=""
    >
      <div overflow=""></div>
    </amp-ad>
    <!-- art -->
    <!-- <amp-ad
      layout="fixed"
      width="728"
      height="90"
      type="adsense"
      data-ad-client="ca-pub-3835341868730147"
      data-ad-slot="6649132469"
    >
    </amp-ad>
    <amp-auto-ads type="adsense" data-ad-client="ca-pub-3835341868730147">
    </amp-auto-ads> -->
    <div id="ad-container" align="center"></div>
    <div class="homeMainBody">
      <div class="container">
        <div class="game-block-outer game-guangg-top">
          <!-- bigFlag特色游戏 -->
          <!--  gameBox-single常规 game-big-box特色 -->
          <div
            v-for="gameItem in gameList"
            :key="gameItem.name"
            :href="gameItem.aHerf"
            alt="Paper Doll For Girls Dress Up"
            class="gameBox-single"
            :class="gameItem.bigFlag ? 'game-big-box' : ''"
            @click="navigateToGame(gameItem.aHerf)"
          >
            <div class="game-tooltip1" v-if="gameItem.bigFlag">
              <!-- 特色图标 -->
              <img :src="specialGameImg" />
              <span class="tooltip-txt1">Featured</span>
            </div>
            <img
              :src="gameItem.gameImageUrl"
              :alt="gameItem.alt"
              class="game-single-thumb"
            />
            <div class="game-hov-back">
              <p class="game-name">{{ gameItem.gameName }}</p>
            </div>
          </div>
        </div>

        <div class="heading-block">
          <h2>Trending Games</h2>
          <div
            class="google-anno-skip google-anno-sc"
            tabindex="0"
            role="link"
            aria-label="Best video game consoles"
            data-google-vignette="false"
            data-google-interstitial="false"
            style="
              display: inline-block !important;
              border-radius: 20px !important;
              padding: 3px 7px 3px 6px !important;
              border-width: 1px !important;
              border-style: solid !important;
              color: rgb(26, 115, 232) !important;
              font-family: Roboto !important;
              font-weight: 500 !important;
              font-size: 14px !important;
              border-color: rgb(215, 215, 215) !important;
              background: rgb(255, 255, 255) !important;
              cursor: pointer !important;
              margin-top: -3px !important;
            "
          >
            <span
              style="
                display: inline-block !important;
                padding-left: 3px !important;
              "
              ><svg
                viewBox="0 -960 960 960"
                width="14px"
                height="14px"
                style="
                  color-scheme: initial !important;
                  forced-color-adjust: initial !important;
                  mask: initial !important;
                  math-depth: initial !important;
                  position: relative !important;
                  position-anchor: initial !important;
                  text-size-adjust: initial !important;
                  appearance: initial !important;
                  color: initial !important;
                  font: initial !important;
                  font-palette: initial !important;
                  font-synthesis: initial !important;
                  position-area: initial !important;
                  text-orientation: initial !important;
                  text-rendering: initial !important;
                  text-spacing-trim: initial !important;
                  -webkit-font-smoothing: initial !important;
                  -webkit-locale: initial !important;
                  -webkit-text-orientation: initial !important;
                  -webkit-writing-mode: initial !important;
                  writing-mode: initial !important;
                  zoom: initial !important;
                  accent-color: initial !important;
                  place-content: initial !important;
                  place-items: initial !important;
                  place-self: initial !important;
                  alignment-baseline: initial !important;
                  anchor-name: initial !important;
                  anchor-scope: initial !important;
                  animation-composition: initial !important;
                  animation: initial !important;
                  app-region: initial !important;
                  aspect-ratio: initial !important;
                  backdrop-filter: initial !important;
                  backface-visibility: initial !important;
                  background: initial !important;
                  background-blend-mode: initial !important;
                  baseline-shift: initial !important;
                  baseline-source: initial !important;
                  block-size: initial !important;
                  border-block: initial !important;
                  border: 0px !important;
                  border-radius: initial !important;
                  border-collapse: initial !important;
                  border-end-end-radius: initial !important;
                  border-end-start-radius: initial !important;
                  border-inline: initial !important;
                  border-start-end-radius: initial !important;
                  border-start-start-radius: initial !important;
                  bottom: initial !important;
                  box-decoration-break: initial !important;
                  box-shadow: none !important;
                  box-sizing: initial !important;
                  break-after: initial !important;
                  break-before: initial !important;
                  break-inside: initial !important;
                  buffered-rendering: initial !important;
                  caption-side: initial !important;
                  caret-color: initial !important;
                  clear: initial !important;
                  clip: initial !important;
                  clip-path: initial !important;
                  clip-rule: initial !important;
                  color-interpolation: initial !important;
                  color-interpolation-filters: initial !important;
                  color-rendering: initial !important;
                  columns: initial !important;
                  column-fill: initial !important;
                  gap: initial !important;
                  column-rule: initial !important;
                  column-span: initial !important;
                  contain: initial !important;
                  contain-intrinsic-block-size: initial !important;
                  contain-intrinsic-size: initial !important;
                  contain-intrinsic-inline-size: initial !important;
                  container: initial !important;
                  content: initial !important;
                  content-visibility: initial !important;
                  counter-increment: initial !important;
                  counter-reset: initial !important;
                  counter-set: initial !important;
                  cursor: inherit !important;
                  cx: initial !important;
                  cy: initial !important;
                  d: initial !important;
                  display: inline !important;
                  dominant-baseline: initial !important;
                  empty-cells: initial !important;
                  field-sizing: initial !important;
                  fill: rgb(26, 115, 232) !important;
                  fill-opacity: initial !important;
                  fill-rule: initial !important;
                  filter: initial !important;
                  flex: initial !important;
                  flex-flow: initial !important;
                  float: none !important;
                  flood-color: initial !important;
                  flood-opacity: initial !important;
                  grid: initial !important;
                  grid-area: initial !important;
                  height: initial !important;
                  hyphenate-character: initial !important;
                  hyphenate-limit-chars: initial !important;
                  hyphens: initial !important;
                  image-orientation: initial !important;
                  image-rendering: initial !important;
                  initial-letter: initial !important;
                  inline-size: initial !important;
                  inset-block: initial !important;
                  inset-inline: initial !important;
                  interpolate-size: initial !important;
                  isolation: initial !important;
                  left: initial !important;
                  letter-spacing: initial !important;
                  lighting-color: initial !important;
                  line-break: initial !important;
                  list-style: initial !important;
                  margin-block: initial !important;
                  margin: 0px !important;
                  margin-inline: initial !important;
                  marker: initial !important;
                  mask-type: initial !important;
                  math-shift: initial !important;
                  math-style: initial !important;
                  max-block-size: initial !important;
                  max-height: initial !important;
                  max-inline-size: initial !important;
                  max-width: initial !important;
                  min-block-size: initial !important;
                  min-height: initial !important;
                  min-inline-size: initial !important;
                  min-width: initial !important;
                  mix-blend-mode: initial !important;
                  object-fit: initial !important;
                  object-position: initial !important;
                  object-view-box: initial !important;
                  offset: initial !important;
                  opacity: initial !important;
                  order: initial !important;
                  orphans: initial !important;
                  outline: 0px !important;
                  outline-offset: initial !important;
                  overflow-anchor: initial !important;
                  overflow-clip-margin: initial !important;
                  overflow-wrap: initial !important;
                  overflow: initial !important;
                  overlay: initial !important;
                  overscroll-behavior-block: initial !important;
                  overscroll-behavior-inline: initial !important;
                  overscroll-behavior: initial !important;
                  padding-block: initial !important;
                  padding: 0px !important;
                  padding-inline: initial !important;
                  page: initial !important;
                  page-orientation: initial !important;
                  paint-order: initial !important;
                  perspective: initial !important;
                  perspective-origin: initial !important;
                  pointer-events: initial !important;
                  position-try: initial !important;
                  position-visibility: initial !important;
                  quotes: initial !important;
                  r: initial !important;
                  resize: initial !important;
                  right: initial !important;
                  rotate: initial !important;
                  ruby-align: initial !important;
                  ruby-position: initial !important;
                  rx: initial !important;
                  ry: initial !important;
                  scale: initial !important;
                  scroll-behavior: initial !important;
                  scroll-margin-block: initial !important;
                  scroll-margin: initial !important;
                  scroll-margin-inline: initial !important;
                  scroll-padding-block: initial !important;
                  scroll-padding: initial !important;
                  scroll-padding-inline: initial !important;
                  scroll-snap-align: initial !important;
                  scroll-snap-stop: initial !important;
                  scroll-snap-type: initial !important;
                  scroll-timeline: initial !important;
                  scrollbar-color: initial !important;
                  scrollbar-gutter: initial !important;
                  scrollbar-width: initial !important;
                  shape-image-threshold: initial !important;
                  shape-margin: initial !important;
                  shape-outside: initial !important;
                  shape-rendering: initial !important;
                  size: initial !important;
                  speak: initial !important;
                  stop-color: initial !important;
                  stop-opacity: initial !important;
                  stroke: initial !important;
                  stroke-dasharray: initial !important;
                  stroke-dashoffset: initial !important;
                  stroke-linecap: initial !important;
                  stroke-linejoin: initial !important;
                  stroke-miterlimit: initial !important;
                  stroke-opacity: initial !important;
                  stroke-width: initial !important;
                  tab-size: initial !important;
                  table-layout: initial !important;
                  text-align: initial !important;
                  text-align-last: initial !important;
                  text-anchor: initial !important;
                  text-combine-upright: initial !important;
                  text-decoration: initial !important;
                  text-decoration-skip-ink: initial !important;
                  text-emphasis: initial !important;
                  text-emphasis-position: initial !important;
                  text-indent: initial !important;
                  text-overflow: initial !important;
                  text-shadow: initial !important;
                  text-transform: initial !important;
                  text-underline-offset: initial !important;
                  text-underline-position: initial !important;
                  text-wrap: initial !important;
                  timeline-scope: initial !important;
                  top: 3px !important;
                  touch-action: initial !important;
                  transform: initial !important;
                  transform-box: initial !important;
                  transform-origin: initial !important;
                  transform-style: initial !important;
                  transition: initial !important;
                  translate: initial !important;
                  user-select: initial !important;
                  vector-effect: initial !important;
                  vertical-align: initial !important;
                  view-timeline: initial !important;
                  view-transition-class: initial !important;
                  view-transition-name: initial !important;
                  visibility: initial !important;
                  border-spacing: initial !important;
                  -webkit-box-align: initial !important;
                  -webkit-box-decoration-break: initial !important;
                  -webkit-box-direction: initial !important;
                  -webkit-box-flex: initial !important;
                  -webkit-box-ordinal-group: initial !important;
                  -webkit-box-orient: initial !important;
                  -webkit-box-pack: initial !important;
                  -webkit-box-reflect: initial !important;
                  -webkit-line-break: initial !important;
                  -webkit-line-clamp: initial !important;
                  -webkit-mask-box-image: initial !important;
                  -webkit-print-color-adjust: initial !important;
                  -webkit-rtl-ordering: initial !important;
                  -webkit-ruby-position: initial !important;
                  -webkit-tap-highlight-color: initial !important;
                  -webkit-text-combine: initial !important;
                  -webkit-text-decorations-in-effect: initial !important;
                  -webkit-text-fill-color: unset !important;
                  -webkit-text-security: initial !important;
                  -webkit-text-stroke: initial !important;
                  -webkit-user-drag: initial !important;
                  white-space-collapse: initial !important;
                  widows: initial !important;
                  width: initial !important;
                  will-change: initial !important;
                  word-break: initial !important;
                  word-spacing: initial !important;
                  x: initial !important;
                  y: initial !important;
                  z-index: initial !important;
                "
              >
                <path
                  d="M503-104q-24 24-57 24t-57-24L103-390q-23-23-23-56.5t23-56.5l352-353q11-11 26-17.5t32-6.5h286q33 0 56.5 23.5T879-800v286q0 17-6.5 32T855-456L503-104Zm196-536q25 0 42.5-17.5T759-700q0-25-17.5-42.5T699-760q-25 0-42.5 17.5T639-700q0 25 17.5 42.5T699-640ZM446-160l353-354v-286H513L160-446l286 286Zm353-640Z"
                ></path></svg></span
            ><span
              style="
                position: relative !important;
                left: 3px !important;
                padding-right: 6px !important;
              "
              >Best video game consoles</span
            >
          </div>
        </div>
        <div class="game-block-outer">
          <div
            v-for="gameItem in trendingGameslist"
            :key="gameItem.name"
            :href="gameItem.aHerf"
            alt="Paper Doll For Girls Dress Up"
            class="gameBox-single"
            :class="gameItem.bigFlag ? 'game-big-box' : ''"
            @click="navigateToGame(gameItem.aHerf)"
          >
            <div class="game-tooltip1" v-if="gameItem.bigFlag">
              <!-- 特色图标 -->
              <img :src="specialGameImg" class="tooltip-img1" />
              <span class="tooltip-txt1">Featured</span>
            </div>
            <img
              :src="gameItem.gameImageUrl"
              loading="lazy"
              :alt="gameItem.alt"
              class="game-single-thumb"
            />
            <div class="game-hov-back">
              <p class="game-name">{{ gameItem.gameName }}</p>
            </div>
          </div>
        </div>

        <div class="heading-block">
          <h2>Check This Out!</h2>
        </div>

        <div class="game-block-outer2">
          <div
            v-for="gameItem in CheckGameList"
            :key="gameItem.name"
            :href="gameItem.aHerf"
            class="gameBox-single1"
            @click="navigateToGame(gameItem.aHerf)"
          >
            <div class="flex gamesingleFlex">
              <div class="cont">
                <img
                  :src="gameItem.gameImageUrl"
                  loading="lazy"
                  :alt="gameItem.alt"
                  class="gamesingle1Thumb"
                />
              </div>
              <div class="cont">
                <p class="name">{{ gameItem.gameName }}</p>
                <p class="cat">{{ gameItem.gameSubName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blogcont">
      <div class="container">
        <div class="heading-block flex ai jcb">
          <h2>Our Blogs</h2>
        </div>
        <!-- heading-block -->
        <div class="inner">
          <div class="blogSlider slick-initialized slick-slider slick-dotted">
            <button
              class="slick-prev slick-arrow slick-disabled"
              aria-label="Previous"
              type="button"
              aria-disabled="true"
              style="display: inline-block"
            >
              Previous
            </button>
            <div class="slick-list draggable">
              <div
                class="slick-track"
                style="
                  opacity: 1;
                  width: 2238px;
                  transform: translate3d(0px, 0px, 0px);
                "
              >
                <el-carousel :interval="3000" type="card">
                  <el-carousel-item v-for="item in lunboList" :key="item">
                    <div
                      class="blog-slide slick-slide slick-current slick-active"
                      style="width: 373px"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide00"
                      aria-describedby="slick-slide-control00"
                      data-slick-index="0"
                      aria-hidden="false"
                    >
                      <div class="blogBox">
                        <div
                          :href="item.aHerf"
                          class="thumbBack"
                          tabindex="0"
                          @click="navigateToGame(item.aHerf)"
                        >
                          <img :src="item.gameImageUrl" class="blogthumbimg" />
                        </div>
                        <a
                          href="https://artmutualfunds.com/blog/bubble-shooter-pro-2"
                          class="title"
                          tabindex="0"
                        >
                          {{ item.gameName }}</a
                        >
                        <p class="desc">
                          {{ item.desc }}
                        </p>
                        <a
                          href="https://artmutualfunds.com/blog/bubble-shooter-pro-2"
                          class="blogBtn"
                          tabindex="0"
                          >Read more <i class="fa fa-angle-right"></i
                        ></a>
                        <div
                          class="google-anno-skip google-anno-sc"
                          tabindex="0"
                          role="link"
                          aria-label="Best video game consoles"
                          data-google-vignette="false"
                          data-google-interstitial="false"
                          style="
                            display: inline-block !important;
                            border-radius: 20px !important;
                            padding: 3px 7px 3px 6px !important;
                            border-width: 1px !important;
                            border-style: solid !important;
                            color: rgb(26, 115, 232) !important;
                            font-family: Roboto !important;
                            font-weight: 500 !important;
                            font-size: 14px !important;
                            border-color: rgb(215, 215, 215) !important;
                            background: rgb(255, 255, 255) !important;
                            cursor: pointer !important;
                            margin-top: -3px !important;
                          "
                        >
                          <span
                            style="
                              display: inline-block !important;
                              padding-left: 3px !important;
                            "
                            ><svg
                              viewBox="0 -960 960 960"
                              width="14px"
                              height="14px"
                              style="
                                color-scheme: initial !important;
                                forced-color-adjust: initial !important;
                                mask: initial !important;
                                math-depth: initial !important;
                                position: relative !important;
                                position-anchor: initial !important;
                                text-size-adjust: initial !important;
                                appearance: initial !important;
                                color: initial !important;
                                font: initial !important;
                                font-palette: initial !important;
                                font-synthesis: initial !important;
                                position-area: initial !important;
                                text-orientation: initial !important;
                                text-rendering: initial !important;
                                text-spacing-trim: initial !important;
                                -webkit-font-smoothing: initial !important;
                                -webkit-locale: initial !important;
                                -webkit-text-orientation: initial !important;
                                -webkit-writing-mode: initial !important;
                                writing-mode: initial !important;
                                zoom: initial !important;
                                accent-color: initial !important;
                                place-content: initial !important;
                                place-items: initial !important;
                                place-self: initial !important;
                                alignment-baseline: initial !important;
                                anchor-name: initial !important;
                                anchor-scope: initial !important;
                                animation-composition: initial !important;
                                animation: initial !important;
                                app-region: initial !important;
                                aspect-ratio: initial !important;
                                backdrop-filter: initial !important;
                                backface-visibility: initial !important;
                                background: initial !important;
                                background-blend-mode: initial !important;
                                baseline-shift: initial !important;
                                baseline-source: initial !important;
                                block-size: initial !important;
                                border-block: initial !important;
                                border: 0px !important;
                                border-radius: initial !important;
                                border-collapse: initial !important;
                                border-end-end-radius: initial !important;
                                border-end-start-radius: initial !important;
                                border-inline: initial !important;
                                border-start-end-radius: initial !important;
                                border-start-start-radius: initial !important;
                                bottom: initial !important;
                                box-decoration-break: initial !important;
                                box-shadow: none !important;
                                box-sizing: initial !important;
                                break-after: initial !important;
                                break-before: initial !important;
                                break-inside: initial !important;
                                buffered-rendering: initial !important;
                                caption-side: initial !important;
                                caret-color: initial !important;
                                clear: initial !important;
                                clip: initial !important;
                                clip-path: initial !important;
                                clip-rule: initial !important;
                                color-interpolation: initial !important;
                                color-interpolation-filters: initial !important;
                                color-rendering: initial !important;
                                columns: initial !important;
                                column-fill: initial !important;
                                gap: initial !important;
                                column-rule: initial !important;
                                column-span: initial !important;
                                contain: initial !important;
                                contain-intrinsic-block-size: initial !important;
                                contain-intrinsic-size: initial !important;
                                contain-intrinsic-inline-size: initial !important;
                                container: initial !important;
                                content: initial !important;
                                content-visibility: initial !important;
                                counter-increment: initial !important;
                                counter-reset: initial !important;
                                counter-set: initial !important;
                                cursor: inherit !important;
                                cx: initial !important;
                                cy: initial !important;
                                d: initial !important;
                                display: inline !important;
                                dominant-baseline: initial !important;
                                empty-cells: initial !important;
                                field-sizing: initial !important;
                                fill: rgb(26, 115, 232) !important;
                                fill-opacity: initial !important;
                                fill-rule: initial !important;
                                filter: initial !important;
                                flex: initial !important;
                                flex-flow: initial !important;
                                float: none !important;
                                flood-color: initial !important;
                                flood-opacity: initial !important;
                                grid: initial !important;
                                grid-area: initial !important;
                                height: initial !important;
                                hyphenate-character: initial !important;
                                hyphenate-limit-chars: initial !important;
                                hyphens: initial !important;
                                image-orientation: initial !important;
                                image-rendering: initial !important;
                                initial-letter: initial !important;
                                inline-size: initial !important;
                                inset-block: initial !important;
                                inset-inline: initial !important;
                                interpolate-size: initial !important;
                                isolation: initial !important;
                                left: initial !important;
                                letter-spacing: initial !important;
                                lighting-color: initial !important;
                                line-break: initial !important;
                                list-style: initial !important;
                                margin-block: initial !important;
                                margin: 0px !important;
                                margin-inline: initial !important;
                                marker: initial !important;
                                mask-type: initial !important;
                                math-shift: initial !important;
                                math-style: initial !important;
                                max-block-size: initial !important;
                                max-height: initial !important;
                                max-inline-size: initial !important;
                                max-width: initial !important;
                                min-block-size: initial !important;
                                min-height: initial !important;
                                min-inline-size: initial !important;
                                min-width: initial !important;
                                mix-blend-mode: initial !important;
                                object-fit: initial !important;
                                object-position: initial !important;
                                object-view-box: initial !important;
                                offset: initial !important;
                                opacity: initial !important;
                                order: initial !important;
                                orphans: initial !important;
                                outline: 0px !important;
                                outline-offset: initial !important;
                                overflow-anchor: initial !important;
                                overflow-clip-margin: initial !important;
                                overflow-wrap: initial !important;
                                overflow: initial !important;
                                overlay: initial !important;
                                overscroll-behavior-block: initial !important;
                                overscroll-behavior-inline: initial !important;
                                overscroll-behavior: initial !important;
                                padding-block: initial !important;
                                padding: 0px !important;
                                padding-inline: initial !important;
                                page: initial !important;
                                page-orientation: initial !important;
                                paint-order: initial !important;
                                perspective: initial !important;
                                perspective-origin: initial !important;
                                pointer-events: initial !important;
                                position-try: initial !important;
                                position-visibility: initial !important;
                                quotes: initial !important;
                                r: initial !important;
                                resize: initial !important;
                                right: initial !important;
                                rotate: initial !important;
                                ruby-align: initial !important;
                                ruby-position: initial !important;
                                rx: initial !important;
                                ry: initial !important;
                                scale: initial !important;
                                scroll-behavior: initial !important;
                                scroll-margin-block: initial !important;
                                scroll-margin: initial !important;
                                scroll-margin-inline: initial !important;
                                scroll-padding-block: initial !important;
                                scroll-padding: initial !important;
                                scroll-padding-inline: initial !important;
                                scroll-snap-align: initial !important;
                                scroll-snap-stop: initial !important;
                                scroll-snap-type: initial !important;
                                scroll-timeline: initial !important;
                                scrollbar-color: initial !important;
                                scrollbar-gutter: initial !important;
                                scrollbar-width: initial !important;
                                shape-image-threshold: initial !important;
                                shape-margin: initial !important;
                                shape-outside: initial !important;
                                shape-rendering: initial !important;
                                size: initial !important;
                                speak: initial !important;
                                stop-color: initial !important;
                                stop-opacity: initial !important;
                                stroke: initial !important;
                                stroke-dasharray: initial !important;
                                stroke-dashoffset: initial !important;
                                stroke-linecap: initial !important;
                                stroke-linejoin: initial !important;
                                stroke-miterlimit: initial !important;
                                stroke-opacity: initial !important;
                                stroke-width: initial !important;
                                tab-size: initial !important;
                                table-layout: initial !important;
                                text-align: initial !important;
                                text-align-last: initial !important;
                                text-anchor: initial !important;
                                text-combine-upright: initial !important;
                                text-decoration: initial !important;
                                text-decoration-skip-ink: initial !important;
                                text-emphasis: initial !important;
                                text-emphasis-position: initial !important;
                                text-indent: initial !important;
                                text-overflow: initial !important;
                                text-shadow: initial !important;
                                text-transform: initial !important;
                                text-underline-offset: initial !important;
                                text-underline-position: initial !important;
                                text-wrap: initial !important;
                                timeline-scope: initial !important;
                                top: 3px !important;
                                touch-action: initial !important;
                                transform: initial !important;
                                transform-box: initial !important;
                                transform-origin: initial !important;
                                transform-style: initial !important;
                                transition: initial !important;
                                translate: initial !important;
                                user-select: initial !important;
                                vector-effect: initial !important;
                                vertical-align: initial !important;
                                view-timeline: initial !important;
                                view-transition-class: initial !important;
                                view-transition-name: initial !important;
                                visibility: initial !important;
                                border-spacing: initial !important;
                                -webkit-box-align: initial !important;
                                -webkit-box-decoration-break: initial !important;
                                -webkit-box-direction: initial !important;
                                -webkit-box-flex: initial !important;
                                -webkit-box-ordinal-group: initial !important;
                                -webkit-box-orient: initial !important;
                                -webkit-box-pack: initial !important;
                                -webkit-box-reflect: initial !important;
                                -webkit-line-break: initial !important;
                                -webkit-line-clamp: initial !important;
                                -webkit-mask-box-image: initial !important;
                                -webkit-print-color-adjust: initial !important;
                                -webkit-rtl-ordering: initial !important;
                                -webkit-ruby-position: initial !important;
                                -webkit-tap-highlight-color: initial !important;
                                -webkit-text-combine: initial !important;
                                -webkit-text-decorations-in-effect: initial !important;
                                -webkit-text-fill-color: unset !important;
                                -webkit-text-security: initial !important;
                                -webkit-text-stroke: initial !important;
                                -webkit-user-drag: initial !important;
                                white-space-collapse: initial !important;
                                widows: initial !important;
                                width: initial !important;
                                will-change: initial !important;
                                word-break: initial !important;
                                word-spacing: initial !important;
                                x: initial !important;
                                y: initial !important;
                                z-index: initial !important;
                              "
                            >
                              <path
                                d="M503-104q-24 24-57 24t-57-24L103-390q-23-23-23-56.5t23-56.5l352-353q11-11 26-17.5t32-6.5h286q33 0 56.5 23.5T879-800v286q0 17-6.5 32T855-456L503-104Zm196-536q25 0 42.5-17.5T759-700q0-25-17.5-42.5T699-760q-25 0-42.5 17.5T639-700q0 25 17.5 42.5T699-640ZM446-160l353-354v-286H513L160-446l286 286Zm353-640Z"
                              ></path></svg></span
                          ><span
                            style="
                              position: relative !important;
                              left: 3px !important;
                              padding-right: 6px !important;
                            "
                            >Best video game consoles</span
                          >
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <b
                  class="slick-slide"
                  style="width: 373px"
                  tabindex="-1"
                  role="tabpanel"
                  id="slick-slide05"
                  aria-describedby="slick-slide-control05"
                  data-slick-index="5"
                  aria-hidden="true"
                  ><b>
                    <!--Crime Theft Gangster Paradise-->
                  </b></b
                >
              </div>
            </div>
          </div>
          <b><b> </b></b>
        </div>
        <b><b> </b></b>
      </div>
      <b><b> </b></b>
    </div>
    <!-- xmxmy -->
    <amp-ad
      width="100vw"
      height="320"
      type="adsense"
      data-ad-client="ca-pub-6741681473423260"
      data-ad-slot="2805789712"
      data-auto-format="rspv"
      data-full-width=""
    >
      <div overflow=""></div>
    </amp-ad>
    <!-- art -->
    <!-- <amp-ad
      layout="fixed"
      width="728"
      height="90"
      type="adsense"
      data-ad-client="ca-pub-3835341868730147"
      data-ad-slot="6649132469"
    >
    </amp-ad>
    <amp-auto-ads type="adsense" data-ad-client="ca-pub-3835341868730147">
    </amp-auto-ads> -->
    <div id="ad-container2" align="center"></div>
    <b
      ><b>
        <div class="footer">
          <div class="container">
            <div class="inner">
              <div class="row jcb">
                <div class="col-lg-6">
                  <p>All rights reserved by artmutualfunds.com © 2024.</p>
                </div>
                <div class="col-lg-6" align="right">
                  <div class="flex footlinksflex jce">
                    <a href="https://artmutualfunds.com/blog">Blog</a>
                    <a href="https://artmutualfunds.com/about">About</a>
                    <a href="https://artmutualfunds.com/contact">
                      Contact Us
                    </a>
                    <a
                      href="https://artmutualfunds.com/page/terms-and-conditions"
                    >
                      Terms and Conditions
                    </a>
                    <a href="https://artmutualfunds.com/page/privacy-policy">
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b></b
    >
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Search, Sunny, Moon } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
const router = useRouter();

const searchQuery = ref("");
const activeIndex = ref("Arcade");

const loadAds = () => {
  // 创建并配置 <script> 元素
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6741681473423260";
  script.crossOrigin = "anonymous";

  // 创建并配置 <ins> 元素
  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "inline-block";
  ins.style.width = "728px";
  ins.style.height = "90px";
  // xmxmy
  // ins.setAttribute("data-ad-client", "ca-pub-5450991634355122");
  // art
  // ins.setAttribute("data-ad-client", "ca-pub-3835341868730147");
  // ins.setAttribute("data-ad-slot", "1234567890");
  // luck37
  ins.setAttribute("data-ad-client", "ca-pub-6741681473423260");
  ins.setAttribute("data-ad-slot", "6265220201");
  // 获取容器元素
  const adContainer = document.getElementById("ad-container");
  if (adContainer) {
    adContainer.appendChild(ins);
    adContainer.appendChild(script);

    // 初始化广告
    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000); // 根据需要调整延迟时间
  } else {
    console.error("Ad container not found!");
  }
};
const loadAds2 = () => {
  // 创建并配置 <script> 元素
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6741681473423260";
  script.crossOrigin = "anonymous";

  // 创建并配置 <ins> 元素
  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "inline-block";
  ins.style.width = "728px";
  ins.style.height = "90px";
  // xmxmy
  // ins.setAttribute("data-ad-client", "ca-pub-5450991634355122");
  // art
  // ins.setAttribute("data-ad-client", "ca-pub-3835341868730147");
  // ins.setAttribute("data-ad-slot", "1234567890");
  // luck37
  ins.setAttribute("data-ad-client", "ca-pub-6741681473423260");
  ins.setAttribute("data-ad-slot", "6265220201");

  // 获取容器元素
  const adContainer = document.getElementById("ad-container2");
  if (adContainer) {
    adContainer.appendChild(ins);
    adContainer.appendChild(script);

    // 初始化广告
    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000); // 根据需要调整延迟时间
  } else {
    console.error("Ad container not found!");
  }
};

// 在组件挂载后加载广告
onMounted(() => {
  loadAds();
  loadAds2();
});

const categories = [
  {
    name: "Arcade",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/arcade.png",
    alt: "Arcade Games by artmutualfunds.com",
  },
  {
    name: "Action",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/action.png",
    alt: "Action Games by artmutualfunds.com",
  },
  {
    name: "Adventure",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/adventure.png",
    alt: "Adventure Games by artmutualfunds.com",
  },
  {
    name: "Shooting",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/shooting.png",
    alt: "Shooting Games by artmutualfunds.com",
  },
  {
    name: "Girl",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/girl.png",
    alt: "Girl Games by artmutualfunds.com",
  },
  {
    name: "Puzzle",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/puzzle.png",
    alt: "Puzzle Games by artmutualfunds.com",
  },
  {
    name: "Sport",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/sport.png",
    alt: "Sport Games by artmutualfunds.com",
  },
  {
    name: "Horror",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/horror.png",
    alt: "Horror Games by artmutualfunds.com",
  },
  {
    name: "Board",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/board.png",
    alt: "Board Games by artmutualfunds.com",
  },
];
// 前置游戏
const gameList = [
  {
    gameName: "Paper Doll Fo",
    aHerf: "1",
    gameImageUrl: require("@/assets/image/y1.png"),
    bigFlag: false,
    alt: "Paper Doll For Girls Dress Up",
  },
  {
    gameName: "Color Link Challenge",
    aHerf: "2",
    gameImageUrl: require("@/assets/image/y2.png"),
    bigFlag: false,
    alt: "Color Link Challenge",
  },
  {
    gameName: "Car Jam Color",
    aHerf: "3",
    gameImageUrl: require("@/assets/image/y3.png"),
    bigFlag: true,
    alt: "Car Jam Color",
  },
  {
    gameName: "Happy Glass",
    aHerf: "4",
    gameImageUrl: require("@/assets/image/y4.png"),
    bigFlag: false,
    alt: "Happy Glass",
  },
  {
    gameName: "Slide Blocks",
    aHerf: "5",
    gameImageUrl: require("@/assets/image/y5.png"),
    bigFlag: false,
    alt: "Slide Blocks Puzzle",
  },
  {
    gameName: "Cannon Balls",
    aHerf: "6",
    gameImageUrl: require("@/assets/image/y6.png"),
    bigFlag: false,
    alt: "Cannon Balls - Arcade",
  },
  {
    gameName: "Basketball Sw",
    aHerf: "7",
    gameImageUrl: require("@/assets/image/y7.png"),
    bigFlag: false,
    alt: "Basketball Swooshes",
  },
  {
    gameName: "Build Tower",
    aHerf: "8",
    gameImageUrl: require("@/assets/image/y8.png"),
    bigFlag: false,
    alt: "Build Tower",
  },
  {
    gameName: "Mushroom Pop",
    aHerf: "9",
    gameImageUrl: require("@/assets/image/y9.png"),
    bigFlag: false,
    alt: "Mushroom Pop",
  },
  {
    gameName: "Jumper Bot",
    aHerf: "10",
    gameImageUrl: require("@/assets/image/y10.png"),
    bigFlag: false,
    alt: "Jumper Bot",
  },
  {
    gameName: "Ghost Float",
    aHerf: "11",
    gameImageUrl: require("@/assets/image/y11.png"),
    bigFlag: false,
    alt: "Ghost Float",
  },
  {
    gameName: "Flamingo Bing",
    aHerf: "12",
    gameImageUrl: require("@/assets/image/y12.png"),
    bigFlag: false,
    alt: "Flamingo Bingo",
  },
  {
    gameName: "Zombie Wipeou",
    aHerf: "13",
    gameImageUrl: require("@/assets/image/y13.png"),
    bigFlag: false,
    alt: "Zombie Wipeout",
  },
  {
    gameName: "Dd Pixel Slid",
    aHerf: "14",
    gameImageUrl: require("@/assets/image/y14.png"),
    bigFlag: true,
    alt: "Dd Pixel Slide",
  },
  {
    gameName: "Swipe The Pin",
    aHerf: "15",
    gameImageUrl: require("@/assets/image/y15.png"),
    bigFlag: false,
    alt: "Swipe The Pin",
  },
  {
    gameName: "Oomee Piñata",
    aHerf: "16",
    gameImageUrl: require("@/assets/image/y16.png"),
    bigFlag: false,
    alt: "Oomee Piñata",
  },
  {
    gameName: "Changer Jam",
    aHerf: "17",
    gameImageUrl: require("@/assets/image/y17.png"),
    bigFlag: false,
    alt: "Changer Jam",
  },
  {
    gameName: "Find The Set",
    aHerf: "18",
    gameImageUrl: require("@/assets/image/y18.png"),
    bigFlag: false,
    alt: "Find The Set",
  },
  {
    gameName: "Stickman In J",
    aHerf: "19",
    gameImageUrl: require("@/assets/image/y19.png"),
    bigFlag: false,
    alt: "Stickman In Jail",
  },
  {
    gameName: "Coloring Book",
    aHerf: "20",
    gameImageUrl: require("@/assets/image/y20.png"),
    bigFlag: false,
    alt: "Coloring Book Casino",
  },
  {
    gameName: "Fire And Wate",
    aHerf: "21",
    gameImageUrl: require("@/assets/image/y21.png"),
    bigFlag: false,
    alt: "Fire And Water Stickman",
  },
  {
    gameName: "2048 Champion",
    aHerf: "22",
    gameImageUrl: require("@/assets/image/y22.png"),
    bigFlag: true,
    alt: "2048 Champion",
  },
  {
    gameName: "Clones",
    aHerf: "23",
    gameImageUrl: require("@/assets/image/y23.png"),
    bigFlag: false,
    alt: "Clones",
  },
  {
    gameName: "Firefighter R",
    aHerf: "24",
    gameImageUrl: require("@/assets/image/y24.png"),
    bigFlag: false,
    alt: "Firefighter Rescue Quest",
  },
  {
    gameName: "Dragon's Defe",
    aHerf: "25",
    gameImageUrl: require("@/assets/image/y25.png"),
    bigFlag: false,
    alt: "Dragon's Defense Reign Of Fire",
  },
  {
    gameName: "Chin Up Shin",
    aHerf: "26",
    gameImageUrl: require("@/assets/image/y26.png"),
    bigFlag: true,
    alt: "Chin Up Shin Up",
  },
  {
    gameName: "Counter Craft",
    aHerf: "27",
    gameImageUrl: require("@/assets/image/y27.png"),
    bigFlag: false,
    alt: "Counter Craft 5",
  },
  {
    gameName: "The Bomb",
    aHerf: "28",
    gameImageUrl: require("@/assets/image/y28.png"),
    bigFlag: true,
    alt: "The Bomb",
  },
  {
    gameName: "Princesses At",
    aHerf: "29",
    gameImageUrl: require("@/assets/image/y29.png"),
    bigFlag: false,
    alt: "Princesses At Horror School",
  },
  {
    gameName: "Number Match",
    aHerf: "30",
    gameImageUrl: require("@/assets/image/y30.png"),
    bigFlag: false,
    alt: "Number Match - Match Ten",
  },
  {
    gameName: "Ultimate Noug",
    aHerf: "31",
    gameImageUrl: require("@/assets/image/y31.png"),
    bigFlag: false,
    alt: "Ultimate Noughts &amp; Crosses",
  },
  {
    gameName: "Arrowblob Puz",
    aHerf: "32",
    gameImageUrl: require("@/assets/image/y32.png"),
    bigFlag: false,
    alt: "Arrowblob Puzzle Quest",
  },
  {
    gameName: "Ascend",
    aHerf: "33",
    gameImageUrl: require("@/assets/image/y33.png"),
    bigFlag: false,
    alt: "Ascend",
  },
  {
    gameName: "Race",
    aHerf: "34",
    gameImageUrl: require("@/assets/image/y34.png"),
    bigFlag: false,
    alt: "Race",
  },
  {
    gameName: "Tanks Battle",
    aHerf: "35",
    gameImageUrl: require("@/assets/image/y35.png"),
    bigFlag: false,
    alt: "Tanks Battle Field",
  },
  {
    gameName: "Battlegrid On",
    aHerf: "36",
    gameImageUrl: require("@/assets/image/y36.png"),
    bigFlag: false,
    alt: "Battlegrid Online Pvp Tank Battles",
  },
  {
    gameName: "Pumpkin Of Go",
    aHerf: "37",
    gameImageUrl: require("@/assets/image/y37.png"),
    bigFlag: false,
    alt: "Pumpkin Of Goo",
  },
  {
    gameName: "Apostle's Cre",
    aHerf: "38",
    gameImageUrl: require("@/assets/image/y38.png"),
    bigFlag: false,
    alt: "Apostle's Creed I",
  },
  {
    gameName: "Zombie Runner",
    aHerf: "39",
    gameImageUrl: require("@/assets/image/y39.png"),
    bigFlag: true,
    alt: "Zombie Runner",
  },
  {
    gameName: "Fruit Name Ju",
    aHerf: "40",
    gameImageUrl: require("@/assets/image/y40.png"),
    bigFlag: false,
    alt: "Fruit Name Jump",
  },
  {
    gameName: "Yummy Donut F",
    aHerf: "41",
    gameImageUrl: require("@/assets/image/y41.png"),
    bigFlag: false,
    alt: "Yummy Donut Factory",
  },
  {
    gameName: "Cyberman V Su",
    aHerf: "42",
    gameImageUrl: require("@/assets/image/y42.png"),
    bigFlag: false,
    alt: "Cyberman V Super Blaster",
  },
  {
    gameName: "2048colourbal",
    aHerf: "43",
    gameImageUrl: require("@/assets/image/y43.png"),
    bigFlag: true,
    alt: "2048colourballs",
  },
  {
    gameName: "Simple Math Q",
    aHerf: "44",
    gameImageUrl: require("@/assets/image/y44.png"),
    bigFlag: false,
    alt: "Simple Math Quiz",
  },
  {
    gameName: "Cowboy Clash",
    aHerf: "45",
    gameImageUrl: require("@/assets/image/y45.png"),
    bigFlag: false,
    alt: "Cowboy Clash",
  },
  {
    gameName: "Rail Rush Pro",
    aHerf: "46",
    gameImageUrl: require("@/assets/image/y46.png"),
    bigFlag: false,
    alt: "Rail Rush Pro",
  },
  {
    gameName: "Jewel Aquariu",
    aHerf: "47",
    gameImageUrl: require("@/assets/image/y47.png"),
    bigFlag: false,
    alt: "Jewel Aquarium",
  },
  {
    gameName: "Neon Star Bri",
    aHerf: "48",
    gameImageUrl: require("@/assets/image/y48.png"),
    bigFlag: false,
    alt: "Neon Star Bricks",
  },
  {
    gameName: "Football Jump",
    aHerf: "49",
    gameImageUrl: require("@/assets/image/y49.png"),
    bigFlag: false,
    alt: "Football Jump",
  },
  {
    gameName: "Alternating E",
    aHerf: "50",
    gameImageUrl: require("@/assets/image/y50.png"),
    bigFlag: false,
    alt: "Alternating Escape",
  },
  {
    gameName: "Macro Maze",
    aHerf: "51",
    gameImageUrl: require("@/assets/image/y51.png"),
    bigFlag: false,
    alt: "Macro Maze",
  },
  {
    gameName: "Trick Or Trea",
    aHerf: "52",
    gameImageUrl: require("@/assets/image/y52.png"),
    bigFlag: true,
    alt: "Trick Or Treat Halloween Games",
  },
  {
    gameName: "Princess Hall",
    aHerf: "53",
    gameImageUrl: require("@/assets/image/y53.png"),
    bigFlag: false,
    alt: "Princess Halloween Makeup Halffaces Tutorial",
  },
  {
    gameName: "Tomato Ketchu",
    aHerf: "54",
    gameImageUrl: require("@/assets/image/y54.png"),
    bigFlag: true,
    alt: "Tomato Ketchup",
  },
  {
    gameName: "Crazy Christm",
    aHerf: "55",
    gameImageUrl: require("@/assets/image/y55.png"),
    bigFlag: false,
    alt: "Crazy Christmas Fun",
  },
  {
    gameName: "Jump Up 3d",
    aHerf: "56",
    gameImageUrl: require("@/assets/image/y56.png"),
    bigFlag: false,
    alt: "Jump Up 3d",
  },
  {
    gameName: "Space Battles",
    aHerf: "57",
    gameImageUrl: require("@/assets/image/y57.png"),
    bigFlag: false,
    alt: "Space Battleship Orion",
  },
  {
    gameName: "Words Escapes",
    aHerf: "58",
    gameImageUrl: require("@/assets/image/y58.png"),
    bigFlag: false,
    alt: "Words Escapes - Puzzle",
  },
  {
    gameName: "Tractor Rush",
    aHerf: "59",
    gameImageUrl: require("@/assets/image/y59.png"),
    bigFlag: false,
    alt: "Tractor Rush",
  },
  {
    gameName: "Bag Art Diy 3",
    aHerf: "60",
    gameImageUrl: require("@/assets/image/y60.png"),
    bigFlag: false,
    alt: "Bag Art Diy 3d",
  },
  {
    gameName: "Spinner Quest",
    aHerf: "61",
    gameImageUrl: require("@/assets/image/y61.png"),
    bigFlag: false,
    alt: "Spinner Quest",
  },
  {
    gameName: "Orbia",
    aHerf: "62",
    gameImageUrl: require("@/assets/image/y62.png"),
    bigFlag: false,
    alt: "Orbia",
  },
  {
    gameName: "Ocean Danger",
    aHerf: "63",
    gameImageUrl: require("@/assets/image/y63.png"),
    bigFlag: false,
    alt: "Ocean Danger",
  },
];
const trendingGameslist = [
  {
    gameName: "Fruit Slasher",
    aHerf: "64",
    gameImageUrl: require("@/assets/image/y64.png"),
    bigFlag: false,
    alt: "Fruit Slasher Frenzy",
  },
  {
    gameName: "Balloon Color",
    aHerf: "65",
    gameImageUrl: require("@/assets/image/y65.png"),
    bigFlag: false,
    alt: "Balloon Color Matching",
  },
  {
    gameName: "Bike Burger",
    aHerf: "66",
    gameImageUrl: require("@/assets/image/y66.png"),
    bigFlag: false,
    alt: "Bike Burger",
  },
  {
    gameName: "Doggy Escape",
    aHerf: "67",
    gameImageUrl: require("@/assets/image/y67.png"),
    bigFlag: false,
    alt: "Doggy Escape",
  },
  {
    gameName: "Tob Vs Kov 2",
    aHerf: "68",
    gameImageUrl: require("@/assets/image/y68.png"),
    bigFlag: false,
    alt: "Tob Vs Kov 2",
  },
  {
    gameName: "Let's Go Fish",
    aHerf: "69",
    gameImageUrl: require("@/assets/image/y69.png"),
    bigFlag: false,
    alt: "Let's Go Fishing",
  },
  {
    gameName: "Western Maver",
    aHerf: "70",
    gameImageUrl: require("@/assets/image/y70.png"),
    bigFlag: false,
    alt: "Western Maverick",
  },
  {
    gameName: "Shooting Bloc",
    aHerf: "71",
    gameImageUrl: require("@/assets/image/y71.png"),
    bigFlag: false,
    alt: "Shooting Block",
  },
  {
    gameName: "Tiny Eevee Ph",
    aHerf: "72",
    gameImageUrl: require("@/assets/image/y72.png"),
    bigFlag: false,
    alt: "Tiny Eevee Photo Jigsaw",
  },
  {
    gameName: "Battle Hamste",
    aHerf: "73",
    gameImageUrl: require("@/assets/image/y73.png"),
    bigFlag: false,
    alt: "Battle Hamsters",
  },
  {
    gameName: "Slash Sushi",
    aHerf: "74",
    gameImageUrl: require("@/assets/image/y74.png"),
    bigFlag: false,
    alt: "Slash Sushi",
  },
  {
    gameName: "Wizard Memory",
    aHerf: "75",
    gameImageUrl: require("@/assets/image/y75.png"),
    bigFlag: false,
    alt: "Wizard Memory Match",
  },
  {
    gameName: "Wounded Winte",
    aHerf: "76",
    gameImageUrl: require("@/assets/image/y76.png"),
    bigFlag: false,
    alt: "Wounded Winter A Lakota Story",
  },
  {
    gameName: "Naegi Poker",
    aHerf: "77",
    gameImageUrl: require("@/assets/image/y77.png"),
    bigFlag: false,
    alt: "Naegi Poker",
  },
  {
    gameName: "Slime Simulat",
    aHerf: "78",
    gameImageUrl: require("@/assets/image/y78.png"),
    bigFlag: false,
    alt: "Slime Simulator",
  },
  {
    gameName: "Flapnglide",
    aHerf: "79",
    gameImageUrl: require("@/assets/image/y79.png"),
    bigFlag: false,
    alt: "Flapnglide",
  },
  {
    gameName: "Dr Rockets",
    aHerf: "80",
    gameImageUrl: require("@/assets/image/y80.png"),
    bigFlag: false,
    alt: "Dr Rockets",
  },
  {
    gameName: "Huggy Wuggy I",
    aHerf: "43",
    gameImageUrl: require("@/assets/image/y43.png"),
    bigFlag: false,
    alt: "Huggy Wuggy In Minecraft",
  },
  {
    gameName: "Cubetopia Par",
    aHerf: "52",
    gameImageUrl: require("@/assets/image/y52.png"),
    bigFlag: false,
    alt: "Cubetopia Parkour",
  },
  {
    gameName: "Driver",
    aHerf: "11",
    gameImageUrl: require("@/assets/image/y11.png"),
    bigFlag: false,
    alt: "Driver",
  },
  {
    gameName: "Mini Jumps",
    aHerf: "28",
    gameImageUrl: require("@/assets/image/y28.png"),
    bigFlag: false,
    alt: "Mini Jumps",
  },
  {
    gameName: "Wild Hunting",
    aHerf: "36",
    gameImageUrl: require("@/assets/image/y36.png"),
    bigFlag: false,
    alt: "Wild Hunting Clash",
  },
  {
    gameName: "Monsters Atta",
    aHerf: "19",
    gameImageUrl: require("@/assets/image/y19.png"),
    bigFlag: false,
    alt: "Monsters Attack Shooter",
  },
  {
    gameName: "Undead Walkin",
    aHerf: "47",
    gameImageUrl: require("@/assets/image/y47.png"),
    bigFlag: false,
    alt: "Undead Walking",
  },
  {
    gameName: "Dino Run",
    aHerf: "6",
    gameImageUrl: require("@/assets/image/y6.png"),
    bigFlag: false,
    alt: "Dino Run",
  },
  {
    gameName: "Jamir",
    aHerf: "23",
    gameImageUrl: require("@/assets/image/y23.png"),
    bigFlag: false,
    alt: "Jamir",
  },
  {
    gameName: "Attack In Spa",
    aHerf: "58",
    gameImageUrl: require("@/assets/image/y58.png"),
    bigFlag: false,
    alt: "Attack In Space",
  },
  {
    gameName: "Animal Match",
    aHerf: "31",
    gameImageUrl: require("@/assets/image/y31.png"),
    bigFlag: false,
    alt: "Animal Match Master",
  },
  {
    gameName: "Save The Appl",
    aHerf: "7",
    gameImageUrl: require("@/assets/image/y7.png"),
    bigFlag: false,
    alt: "Save The Apple",
  },
  {
    gameName: "Vik The Climb",
    aHerf: "45",
    gameImageUrl: require("@/assets/image/y45.png"),
    bigFlag: false,
    alt: "Vik The Climber",
  },
  {
    gameName: "Adventure To",
    aHerf: "22",
    gameImageUrl: require("@/assets/image/y22.png"),
    bigFlag: false,
    alt: "Adventure To The Ice Kingdom",
  },
  {
    gameName: "Match Balls",
    aHerf: "39",
    gameImageUrl: require("@/assets/image/y39.png"),
    bigFlag: false,
    alt: "Match Balls",
  },
  {
    gameName: "Neon Invaders",
    aHerf: "55",
    gameImageUrl: require("@/assets/image/y55.png"),
    bigFlag: false,
    alt: "Neon Invaders",
  },
  {
    gameName: "Neon Invaders",
    aHerf: "1",
    gameImageUrl: require("@/assets/image/y1.png"),
    bigFlag: false,
    alt: "Neon Invaders",
  },
];
const CheckGameList = [
  {
    gameName: "Orbia",
    gameImageUrl: require("@/assets/image/y12.png"),
    gameSubName: "casual",
    aHerf: "12",
    alt: "Orbia",
  },
  {
    gameName: "Noob Vs Tnt B",
    gameImageUrl: require("@/assets/image/y34.png"),
    gameSubName: "adventure",
    aHerf: "34",
    alt: "Noob Vs Tnt Boom",
  },
  {
    gameName: "Balloon Color",
    gameImageUrl: require("@/assets/image/y57.png"),
    gameSubName: "arcade",
    aHerf: "57",
    alt: "Balloon Color Matching",
  },
  {
    gameName: "Seven Card Ga",
    gameImageUrl: require("@/assets/image/y41.png"),
    gameSubName: "poker",
    aHerf: "41",
    alt: "Seven Card Game",
  },
  {
    gameName: "Space Out",
    gameImageUrl: require("@/assets/image/y29.png"),
    gameSubName: "retro",
    aHerf: "29",
    alt: "Space Out",
  },
  {
    gameName: "Simon Lite",
    gameImageUrl: require("@/assets/image/y53.png"),
    gameSubName: "memory",
    aHerf: "53",
    alt: "Simon Lite",
  },
  {
    gameName: "Snow Plowing",
    gameImageUrl: require("@/assets/image/y62.png"),
    gameSubName: "simulation",
    aHerf: "62",
    alt: "Snow Plowing Simulator",
  },
  {
    gameName: "Fight Monster",
    gameImageUrl: require("@/assets/image/y78.png"),
    gameSubName: "shooter",
    aHerf: "78",
    alt: "Fight Monsters To Survive!",
  },
  {
    gameName: "Happy Save Pu",
    gameImageUrl: require("@/assets/image/y16.png"),
    gameSubName: "casual",
    aHerf: "16",
    alt: "Happy Save Puzzle",
  },
  {
    gameName: "Save The Sea",
    gameImageUrl: require("@/assets/image/y38.png"),
    gameSubName: "educational",
    aHerf: "38",
    alt: "Save The Sea",
  },
];
// 走马灯game
const lunboList = [
  {
    gameName: "Bubble Shooter Pro 2",
    gameImageUrl: require("@/assets/image/y21.png"),
    desc: "Good Game",
    aHerf: "21",
  },
  {
    gameName: "Insect Battle",
    gameImageUrl: require("@/assets/image/y48.png"),
    desc: "Good Game",
    aHerf: "48",
  },
  {
    gameName: "Fruit Sort Puzzle",
    gameImageUrl: require("@/assets/image/y33.png"),
    desc: "Good Game",
    aHerf: "33",
  },
  {
    gameName: "Fish Challenge",
    gameImageUrl: require("@/assets/image/y59.png"),
    desc: "Good Game",
    aHerf: "59",
  },
  {
    gameName: "look",
    gameImageUrl: require("@/assets/image/y71.png"),
    desc: "Good Game",
    aHerf: "71",
  },
  {
    gameName: "Musical Instr",
    gameImageUrl: require("@/assets/image/y14.png"),
    desc: "Good Game",
    aHerf: "14",
  },
];
// 特色游戏图标
const specialGameImg = require("../../assets/image/specialGame.png");
const navigateToGame = (index) => {
  router.push({ name: "play", query: { gameId: index } });
};
const clickIcon = (index) => {
  router.push({ name: "shouye" });
};

const onSubmit = () => {
  console.log("Search query:", searchQuery.value);
};
</script>

<style lang="scss">
.home-box {
  background: url("../../assets/image/tri.jpg") no-repeat;
  background-size: 100% 100%;
}
.headToprow {
  display: flex;
}
.el-carousel__indicators {
  display: none;
}
</style>
